import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "index",
  },
  {
    path: "/beforeLogin",
    meta: { titel: "我的排名", icon: "loop" },
    component: () => import("../views/award/beforeLogin.vue"),
  },
  {
    path: "/index",
    meta: { titel: "首页", icon: "loop" },
    component: () => import("../views/award/index.vue"),
  },
  {
    path: "/rankaward",
    meta: { titel: "排名奖励", icon: "loop" },
    component: () => import("../views/award/rankaward.vue"),
  },
  {
    path: "/currank",
    meta: { titel: "本期排名", icon: "loop" },
    component: () => import("../views/award/currank.vue"),
  },
  {
    path: "/myrank",
    meta: { titel: "我的排名", icon: "loop" },
    component: () => import("../views/award/myrank.vue"),
  },
  {
    path: "/award",
    meta: { titel: "抽奖", icon: "loop" },
    component: () => import("../views/award/award.vue"),
  },
  {
    path: "/answer",
    meta: { titel: "答题", icon: "loop" },
    component: () => import("../views/award/answer.vue"),
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  next();
});
export default router;
