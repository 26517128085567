<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
document.addEventListener("gesturestart", function (event) {
  event.preventDefault();
});
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
  &::-webkit-resizer {
    display: none;
  }
}
</style>
